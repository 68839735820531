import React from 'react';
import styles from './Navigation.module.scss';
import Icon from 'www/components/Icon';
import BodyText from 'www/components/BodyText';
import classNames from 'classnames';

export default function Subnav({ children }: SubnavProps) {
  return (
    <div className={styles.subnavContent}>
      <div className={styles.subnav}>{children}</div>
    </div>
  );
}

export { Subnav };

export function SubnavColumn({
  title,
  className,
  children,
  isSidebar,
  isAccount,
  style
}: SubnavColumnProps) {
  return (
    <div
      className={classNames(styles.column, className, {
        [styles.sidebar]: isSidebar,
        [styles.account]: isAccount
      })}
      style={style}
    >
      {title && <strong className={styles.columnTitle}>{title}</strong>}
      {children}
    </div>
  );
}

export function SubnavLink({ text, href, icon, description, onClick }: SubnavLinkProps) {
  return (
    <a
      href={href}
      className={classNames(styles.subnavLink, {
        [styles.hasIcon]: !!icon
      })}
      onClick={onClick}
    >
      <Icon
        name={icon}
        className={styles.subnavLinkIcon}
      />
      <div>
        <strong className={styles.subnavLinkLabel}>{text}</strong>
        {description && (
          <div className={styles.subnavLinkDescription}>
            <BodyText>{description}</BodyText>
          </div>
        )}
      </div>
    </a>
  );
}

type SubnavProps = {
  children?: React.ReactNode;
};

type SubnavColumnProps = {
  title?: string;
  className?: string;
  children: React.ReactNode;
  isSidebar?: boolean;
  isAccount?: boolean;
  style?: React.CSSProperties;
};

type SubnavLinkProps = {
  text: string;
  href: string;
  icon?: string;
  description?: string;
  onClick?: any;
};
