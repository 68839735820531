import React from 'react';
import styles from './Header.module.scss';
import Navigation from './Navigation';
import { Content } from 'www/components/layout';
import Logo from 'www/images/logo.svg';

interface IProps {
  user?: { name: string };
  logoutPath: string;
  accountPath: string;
}

export default function Header(props: IProps) {
  return (
    <header
      className={styles.wrapper}
      role="banner"
    >
      <Content className={styles.content}>
        <a
          href="/"
          className={styles.logo}
        >
          <span className="sr">Rhino</span>
          <Logo aria-hidden="true" />
        </a>

        <Navigation {...props} />
      </Content>
    </header>
  );
}
