import React from 'react';
import styles from './RenterTabs.module.scss';
import { Section, SectionTop } from 'www/components/layout';
import { H2 } from 'www/components/Heading';
import Tabs from 'www/components/Tabs';
import { IconText } from 'www/components/Icon';
import BodyText from 'www/components/BodyText';
import { ArrowButton } from 'www/components/Button';
import classNames from 'classnames';
import CashDeposits from './assets/cash-deposits.svg';
import DepositInsurance from './assets/deposit-insurance.svg';
import SecurityDeposit from './assets/standard-security-deposit.svg';
import Apartment from './assets/apartment.svg';
import Shield from './assets/shield.svg';

import depositInsuranceVideo from 'www/videos/renters.mp4';
import depositInsurancePoster from 'www/videos/renters-poster.webp';

import leaseGuaranteeJpg from 'www/images/renters/family-couch.jpg';
import leaseGuaranteeWebp from 'www/images/renters/family-couch.webp';

import lossOfEmploymentJpg from 'www/images/renters/loss_of_employment.jpg';
import lossOfEmploymentWebp from 'www/images/renters/loss_of_employment.webp';

export default function RenterTabs() {
  return (
    <Section className={styles.wrapper}>
      <SectionTop isCenteredDesktop>
        <H2 tagline="What we offer">
          Maximize your cash flow, minimize the <strong>hassle</strong>.
        </H2>
        <p>Flexible products to meet your move in requirements.</p>
      </SectionTop>
      <Tabs tabs={tabs} />
    </Section>
  );
}

function TabContent({
  className,
  title,
  icon,
  headline,
  text,
  buttonText,
  buttonLink,
  children,
  figureProps = {}
}: TabContentProps) {
  return (
    <div className={classNames(styles.tab, className)}>
      <div className={styles.text}>
        <IconText icon={icon}>{title}</IconText>
        <h3>{headline}</h3>
        <BodyText isLarge>{text}</BodyText>
        {buttonText && buttonLink && (
          <ArrowButton href={buttonLink}>{buttonText}</ArrowButton>
        )}
      </div>
      {children && (
        <figure
          {...figureProps}
          className={classNames(styles.figure, figureProps.className)}
        >
          {children}
        </figure>
      )}
    </div>
  );
}

const tabs = [
  {
    text: 'Rhino Deposit Insurance',
    content: (
      <TabContent
        className={styles.depositInsuranceTab}
        icon="lock-stroke"
        title="Renters Deposit Insurance"
        headline="We help meet your unique financial needs"
        text="Rhino can replace your traditional cash deposit with smart, affordable security deposit insurance."
        buttonText="Learn more"
        buttonLink="/products/security-deposit-insurance"
      >
        <video
          src={depositInsuranceVideo}
          poster={depositInsurancePoster}
          controls
        />
      </TabContent>
    )
  },
  {
    text: 'Loss of Employment Protection',
    content: (
      <TabContent
        icon="work"
        title="Loss of Employment Protection"
        headline="Rent protection when you need it most."
        text="Rhino’s Loss of Employment Protection program provides financial security and peace of mind in case of an unexpected job loss. Starting at just $5/month, it’s affordable, reliable coverage for life’s uncertainties."
        buttonText="Get protected now"
        buttonLink="/loss-of-employment-insurance/"
      >
        <div className={styles.leaseGuarantee}>
          <picture>
            <source
              type="image/webp"
              src={lossOfEmploymentWebp}
            />
            <img
              src={lossOfEmploymentJpg}
              alt=""
            />
          </picture>
        </div>
      </TabContent>
    )
  },
  {
    text: 'Cash Deposits',
    content: (
      <TabContent
        icon="cash-stroke"
        title="Cash Deposits"
        headline="Security deposits for the digital age"
        text="Manage your payments digitally and get your deposit back quickly through our user-friendly platform."
        buttonText="Learn more"
        buttonLink="/products/cash"
        figureProps={{ 'aria-hidden': true }}
      >
        <div className={styles.depositOptions}>
          <CashDeposits className={styles.cashDeposits} />
          <div className={styles.depositInsurance}>
            <DepositInsurance />
          </div>
          <SecurityDeposit className={styles.securityDeposit} />
        </div>
      </TabContent>
    )
  },
  {
    text: 'Renters Insurance',
    className: styles.rentersInsuranceTab,
    content: (
      <TabContent
        icon="insurance-stroke"
        title="Renters Insurance"
        headline="Protection for your home and the things you love"
        text="Add renters insurance to your security deposit insurance to maximize your protection. Be ready for anything and everything. Starting at $12/month."
        buttonText="Learn more"
        buttonLink="/products/renters-insurance"
      >
        <div className={styles.rentersInsurance}>
          <Shield className={styles.shield} />
          <div className={styles.circleWrapper}>
            {Array.from({ length: parseInt(styles.pulseCount ?? 5, 10) }).map(
              (_, i) => (
                <div
                  key={i}
                  className={styles.circle}
                />
              )
            )}
          </div>
          <Apartment className={styles.apartment} />
        </div>
      </TabContent>
    )
  },
  {
    text: 'Lease Guarantee',
    content: (
      <TabContent
        icon="report-money-stroke"
        title="Lease Guarantee"
        headline="Don't qualify for your lease on your own? Don't worry, we've got you."
        text="Rhino can act like your guarantor, so you can qualify for the rental you love."
        buttonText="Learn more"
        buttonLink="/products/lease-guarantee"
      >
        <div className={styles.leaseGuarantee}>
          <picture>
            <source
              type="image/webp"
              src={leaseGuaranteeWebp}
            />
            <img
              src={leaseGuaranteeJpg}
              alt=""
            />
          </picture>
        </div>
      </TabContent>
    )
  }
];

type TabContentProps = {
  className?: string;
  title: string;
  icon: string;
  headline: string;
  text: string;
  buttonText?: string;
  buttonLink?: string;
  children?: React.ReactNode;
  figureProps?: React.HTMLProps<HTMLElement>;
};
